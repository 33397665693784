import React, { Component } from 'react';

class Header extends Component {
  itemCount(cartItems) {
    return (cartItems === 1 ? 'Item' : 'Items');
  }

  render() {
    return (
      <div className="header mb-5 bg-secondary py-1">
        <div className="header-text d-flex justify-content-between m-auto">
          <h3
            className="title text-white clickable"
            onClick={() => this.props.setView('catalog', {})}>
            <span className="fas fa-dollar-sign dollar-icon mr-2 dollar-icon"></span>
          Wicked Sales
          </h3>
          <h5
            className="text-white d-flex align-items-center clickable"
            onClick={() => this.props.setView('cart', {})}>
            {this.props.cartItemCount} {this.itemCount(this.props.cartItemCount)}
            <span className="fas fa-shopping-cart ml-2"></span>
          </h5>
        </div>
      </div>);
  }
}

export default Header;
