import React, { Component } from 'react';
import CartSummaryItem from './cart-summary-item';

class CartSummary extends Component {
  checkCartItems() {
    const cartItems = this.props.cartItems;
    let totalCost = 0;

    if (cartItems.length === 0) {
      return (
        <div>
          <h5 className="mb-5">Your cart is empty.</h5>
          <div className="d-flex justify-content-between col-11 p-0 my-5 row ml-1">
            <h4 className="col-sm mb-3 p-0">Item Total $0.00</h4>
            <button
              className="btn btn-primary col-xs-12 col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2"
              onClick={() => { this.props.setView('checkout', {}); }}
              disabled >
                Checkout
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        {
          cartItems.map(cartItem => {
            totalCost += cartItem.price;
            return (
              <div key={cartItem.cartItemId}>
                <CartSummaryItem cartItem={cartItem} />
              </div>
            );
          })
        }
        <div className="d-flex justify-content-between col-11 p-0 my-5 row ml-1">
          <h4 className="col-sm mb-3 p-0">Item Total {`$${(totalCost / 100).toFixed(2)}`}</h4>
          <button
            className="btn btn-primary col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2"
            onClick={() => { this.props.setView('checkout', {}); }}>Checkout</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="col-11 m-auto">
        <button className="btn btn-link text-secondary mb-3 p-0" onClick={() => this.props.setView('catalog', {})}>&lt; Back to catalog</button>
        <h2 className="mb-5">My Cart</h2>
        {this.checkCartItems()}
      </div>
    );
  }
}

export default CartSummary;
