import React from 'react';
import Header from './header';
import Modal from 'react-modal';
import ProductList from './product-list';
import ProductDetails from './product-details';
import CartSummary from './cart-summary';
import CheckoutForm from './checkout-form';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: {
        name: 'catalog',
        params: {}
      },
      cart: [],
      isOpen: true,
      isChecked: false
    };
    this.setView = this.setView.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
  }

  componentDidMount() {
    this.getCartItems();
  }

  getCartItems() {
    fetch('/api/cart')
      .then(res => res.json())
      .then(cartItems => {
        this.setState({
          cart: cartItems
        });
      });
  }

  addToCart(product) {
    fetch('/api/cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(product)
    })
      .then(res => res.json())
      .then(returnedProduct => {
        const cartCopy = this.state.cart.slice();
        cartCopy.push(returnedProduct);
        this.setState({
          cart: cartCopy
        });
      });
  }

  getTotalPrice() {
    let totalCost = 0;
    this.state.cart.map(cartItem => {
      totalCost += cartItem.price;
    });
    return (`$${(totalCost / 100).toFixed(2)}`);
  }

  placeOrder(order) {
    fetch('/api/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: order.name,
        creditCard: order.creditCard,
        shippingAddress: order.shippingAddress
      })
    })
      .then(() => {
        this.setState({
          cart: []
        });
        this.setView('catalog', {});
      });
  }

  toggleChecked() {
    this.setState(state => ({
      isChecked: !state.isChecked
    }));
    if (this.state.isChecked) {
      document.getElementById('modal-button').className = 'mt-5 hidden';
    } else {
      document.getElementById('modal-button').className = 'mt-5';
    }
  }

  closeModal() {
    this.setState({
      isOpen: false
    });
  }

  setView(name, params) {
    this.setState({
      view: {
        name: name,
        params: params
      }
    });
  }

  checkState() {
    if (this.state.isOpen) {
      return <Modal isOpen={this.state.isOpen} >
        <h1 className="text-center mb-5">❗ NOTICE ❗</h1>
        <h5 className="col-9 text-center m-auto">
          This site is only for demonstration purposes only. The products for &quot;sale&quot; on
          this page are fake and no real financial transactions can be made. Do not
          use any personal information on this site.
        </h5>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <input type="checkbox" name="check" id="modal-check" onClick={() => this.toggleChecked()} />
          <label htmlFor="check" className="mb-0 ml-2">
            Check this box if you have read and acknowledge the notice above.
          </label>
        </div>
        <div className="d-flex justify-content-center">
          <button className="mt-5 hidden" id="modal-button" onClick={() => this.closeModal()} >
            Continue
          </button>
        </div>
      </Modal>;
    }
    if (this.state.view.name === 'details') {
      return <ProductDetails
        setView={this.setView}
        params={this.state.view.params}
        addToCart={this.addToCart} />;

    }
    if (this.state.view.name === 'cart') {
      return <CartSummary cartItems={this.state.cart} setView={this.setView} />;
    }

    if (this.state.view.name === 'checkout') {
      return <CheckoutForm placeOrder={this.placeOrder} setView={this.setView} totalPrice={this.getTotalPrice()} />;
    }
    return <ProductList setView={this.setView} />;
  }

  render() {
    return (
      <div>
        <header>
          <Header cartItemCount={this.state.cart.length} setView={this.setView}/>
        </header>
        <main>
          {this.checkState()}
        </main>
      </div>
    );
  }
}
