import React, { Component } from 'react';
import Modal from 'react-modal';
import ProductListItem from './product-list-item';

Modal.setAppElement('#root');
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isChecked: false,
      isOpen: true
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts() {
    fetch('/api/products')
      .then(res => res.json())
      .then(dbProducts => {
        this.setState({
          products: dbProducts
        });
      })
      .catch(err => console.error(err));
  }

  render() {
    const products = this.state.products;
    return (
      <div className="container-fluid d-flex col-11 flex-wrap justify-content-around" >
        {
          products.map(product => {
            const newPrice = `$${(product.price / 100).toFixed(2)}`;
            return (
              <ProductListItem
                key={product.productId}
                productId={product.productId}
                image={product.image}
                name={product.name}
                price={newPrice}
                shortDescription={product.shortDescription}
                setView={this.props.setView}
              />
            );
          })
        }
      </div>
    );
  }
}

export default ProductList;
