import React, { Component } from 'react';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      creditCard: '',
      shippingAddress: ''
    };
  }

  handleNameChange() {
    const nameInput = document.getElementById('name');
    this.setState({
      name: nameInput.value
    });
  }

  handleCreditCardChange() {
    const creditCardInput = document.getElementById('credit-card');
    this.setState({
      creditCard: creditCardInput.value
    });
  }

  handleShippingAddressChange() {
    const shippingAddressInput = document.getElementById('shipping-address');
    this.setState({
      shippingAddress: shippingAddressInput.value
    });
  }

  checkState() {
    if (this.state.name === '' || this.state.creditCard === '' || this.state.shippingAddress === '') {
      return (
        <button
          className="btn btn-primary"
          disabled>
            Place Order
        </button>
      );
    }
    return (
      <button
        className="btn btn-primary"
        onClick={() => {
          event.preventDefault();
          this.props.placeOrder(this.state);
        }}>Place Order</button>
    );
  }

  render() {
    return (
      <form action="" className="w-75 m-auto">
        <h4 className="bg-danger rounded text-light text-center py-3">This is not a real checkout form. Please do not input any personal information into the form.</h4>
        <h1 className="mb-5">Checkout</h1>
        <h5 className="text-secondary">{`Order Total ${this.props.totalPrice}`}</h5>
        <br />
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-control"
            onChange={() => this.handleNameChange()} />
        </div>
        <div className="form-group">
          <label htmlFor="credit-card">Credit Card</label>
          <input
            type="text"
            name="credit-card"
            id="credit-card"
            className="form-control"
            onChange={() => this.handleCreditCardChange()} />
        </div>
        <div className="form-group mb-5">
          <label htmlFor="shipping-address">Shipping Address</label>
          <textarea
            name="shipping-address"
            id="shipping-address"
            className="form-control"
            cols="30" rows="8"
            onChange={() => this.handleShippingAddressChange()}></textarea>
        </div>
        <div className="d-flex justify-content-between row mb-5">
          <button className="btn btn-link text-secondary" onClick={() => this.props.setView('catalog', {})}>&lt; Continue Shopping</button>
          {this.checkState()}
        </div>
      </form>
    );
  }
}

export default CheckoutForm;
