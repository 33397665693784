import React, { Component } from 'react';

class ProductListItem extends Component {
  render() {
    const productInfo = this.props;
    return (
      <div className="card mb-5 product-card" onClick={() => this.props.setView('details', { productId: productInfo.productId })}>
        <div className="my-5 h-50 d-flex align-items-center">
          <img src={productInfo.image} className="card-img-top img-fluid card-image" alt="..."></img>
        </div>
        <div className="card-body mt-5">
          <h5 className="card-title">{productInfo.name}</h5>
          <p className="card-text text-secondary">{productInfo.price}</p>
          <p className="card-text">{productInfo.shortDescription}</p>
        </div>
      </div>
    );
  }
}

export default ProductListItem;
