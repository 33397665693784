import React, { Component } from 'react';

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null
    };
  }

  componentDidMount() {
    fetch(`/api/products/${this.props.params.productId}`)
      .then(res => res.json())
      .then(dbProduct => {
        this.setState({
          product: dbProduct
        });
      });
  }

  render() {
    const product = this.state.product;
    if (this.state.product) {
      return (
        <div>
          <div className="col-11 m-auto p-0">
            <button className="btn btn-link text-secondary" onClick={() => this.props.setView('catalog', {})}>&lt; Back to catalog</button>
          </div>
          <div className="d-flex justify-content-around mt-3">
            <div className="img-container col-sm-5">
              <img src={product.image} alt="..." className="img-fluid" />
            </div>
            <div className="basic-info-container col-sm-5">
              <h3>{product.name}</h3>
              <p className="text-secondary">{`$${(product.price / 100).toFixed(2)}`}</p>
              <p>{product.shortDescription}</p>
              <button className="btn btn-primary" onClick={() => this.props.addToCart(this.state.product)}>Add to Cart</button>
            </div>
          </div>
          <hr className="col-3 my-5"/>
          <p className="col-11 mx-auto">{product.longDescription}</p>
        </div>
      );
    }
    return null;
  }
}

export default ProductDetails;
