import React, { Component } from 'react';

class CartSummaryItem extends Component {
  render() {
    const cartItem = this.props.cartItem;

    return (
      <div className="card mb-3 col-11">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={cartItem.image} className="card-img img-fluid card-image d-flex m-auto" alt="..." />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{cartItem.name}</h5>
              <p className="card-text text-secondary">{`$${(cartItem.price / 100).toFixed(2)}`}</p>
              <p className="card-text">{cartItem.shortDescription}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CartSummaryItem;
